/* eslint-disable react/no-array-index-key */
import { useEffect, useState } from "react"
import propTypes from "prop-types"

import "./upload.scss"
import { useFormContext } from "react-hook-form"
import { Icons } from "components"
import { get, isObject } from "lodash"
import cn from "classnames"

import videoImg from "assets/images/png/videoPlaceholder.png"

function Upload({ name, label, required, isMulti, accept, isRemovable }) {
  const { register, setValue, getValues, formState } = useFormContext()
  register(name)
  const { errors } = formState

  const initalValue = get(getValues(), name, [])
  const [files, setFiles] = useState(initalValue)

  const upload = (e) => {
    if (isMulti) {
      setFiles([...files, ...e.target.files])
    } else {
      setFiles([...e.target.files])
    }
  }

  const getUrl = (file) => {
    if (typeof file === "string") return file
    return isObject(file) ? getUrl(URL.createObjectURL(file)) : ""
  }

  const getName = (file) => {
    if (typeof file === "string") return file
    return get(file, "name")
  }

  const removeItem = (i) => {
    setFiles(files.filter((_, j) => i !== j))
  }

  useEffect(() => {
    setValue(name, files)
  }, [files])

  return (
    <div className="form-group">
      <label className={`form-label ${required ? "required" : ""}`} htmlFor="select">
        {label}
      </label>
      <div className="form-upload">
        <div className="form-upload__header">
          <button type="button" className="form-upload__btn">
            <Icons.Upload />
            <p className="form-upload__btn-text">Yuklash...</p>
            <input type="file" onChange={upload} multiple={isMulti} />
          </button>
        </div>
        {files.length ? (
          <div className="form-upload__list">
            {accept === "image/*"
              ? files.map((file, i) => (
                  <div className="form-upload__item" key={i}>
                    <div className="form-upload__item-img">
                      <img src={getUrl(file)} alt="" />
                      <div className="form-upload__item-delete" onClick={() => removeItem(i)}>
                        <Icons.XIcon />
                      </div>
                    </div>
                  </div>
                ))
              : null}
            {accept === "video/*"
              ? files.map((file, i) => (
                  <div className="form-upload__item" key={i}>
                    <div className="form-upload__item-img">
                      <img src={videoImg} alt="" />
                      <div className="form-upload__item-delete" onClick={() => removeItem(i)}>
                        <Icons.XIcon />
                      </div>
                    </div>
                    <p>{getName(file)}</p>
                  </div>
                ))
              : null}
            {accept === "*"
              ? files.map((file, i) =>
                  file ? (
                    <div
                      onClick={() => {
                        window.open(getUrl(file))
                      }}
                      style={{
                        cursor: "pointer",
                      }}
                    >
                      <div className="form-upload__any-item" key={i}>
                        {isRemovable ? (
                          <div
                            className="form-upload__any-item-delete"
                            onClick={(e) => {
                              e.stopPropagation()
                              removeItem(i)
                            }}
                          >
                            <Icons.XIcon />
                          </div>
                        ) : null}
                        <p>{getName(file)}</p>
                      </div>
                    </div>
                  ) : null,
                )
              : null}
          </div>
        ) : null}
      </div>
      <p className={cn("form__error", { visiable: errors[name] })}>
        {get(errors, `${name}.message`)}
      </p>
    </div>
  )
}

export default Upload

Upload.propTypes = {
  label: propTypes.string,
  name: propTypes.string.isRequired,
  required: propTypes.bool,
  isMulti: propTypes.bool,
  accept: propTypes.string,
}

Upload.defaultProps = {
  label: "",
  required: false,
  isMulti: false,
  accept: "image/*",
}
